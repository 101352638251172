<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item>新闻中心</el-breadcrumb-item>
      <el-breadcrumb-item>新闻列表</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="30">
        <el-form
          :model="queryinfo"
          ref="queryinfoRef"
          :inline="true"
          style="text-align: left; margin-left: 40px"
        >
          <el-form-item prop="type" style="margin-right: 40px">
            <div style="display: inline-block" class="divSpan">新闻类型：</div>
            <div style="display: inline-block">
              <ELselect
                v-model="queryinfo.type"
                :options="Types"
                :optionKey="Typeskeys"
                Splaceholder="请选择"
                @clear="getList"
                style="width: 140px"
              ></ELselect>
            </div>
          </el-form-item>

          <el-form-item prop="title" style="margin-right: 40px">
            <div style="display: inline-block" class="divSpan">新闻标题：</div>
            <div style="display: inline-block">
              <el-input
                placeholder="请输入"
                v-model.trim="queryinfo.title"
                clearable
                @clear="getList"
                style="width: 180px"
              >
              </el-input>
            </div>
          </el-form-item>

          <el-button type="primary" icon="el-icon-search" @click="seachChange"
            >查询</el-button
          >
          <el-button type="info" icon="el-icon-refresh" @click="clean"
            >重置</el-button
          >
        </el-form>
      </el-row>
      <el-row :gutter="30" style="text-align: right; margin-right: 40px">
        <el-button type="primary" @click="addNew">新增新闻</el-button>
        <el-button type="primary" @click="swiperNew">轮播新闻设置</el-button>
        <el-button type="primary" @click="setRecommend">推荐新闻设置</el-button>
      </el-row>

      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="NewList"
          :header-cell-style="{
            textAlign: 'center',
            background: '#FAFAFA',
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: '500',
          }"
          tooltip-effect="dark"
          :cell-style="{
            textAlign: 'center',
          }"
        >
          <el-table-column type="index" min-width="50px"></el-table-column>
          <el-table-column
            prop="title"
            label="新闻标题"
            min-width="150px"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="type"
            label="新闻类型"
            min-width="100px"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-tag>
                {{
                  Number(scope.row.type) == 1 ? Types[0].label : Types[1].label
                }}</el-tag
              ></template
            >
          </el-table-column>
          <el-table-column
            prop="eventTime"
            label="发布日期"
            min-width="150px"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="status"
            label="上架状态"
            min-width="100px"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-tag>
                {{ Number(scope.row.status) == 0 ? "下架" : "上架" }}</el-tag
              ></template
            >
          </el-table-column>

          <el-table-column label="操作" width="300px" fixed="right">
            <template slot-scope="scope">
              <el-button size="mini" @click="ViewNew(scope.row)" type="primary"
                >预览
              </el-button>
              <el-button
                size="mini"
                @click="onShelf(scope.row)"
                :type="Number(scope.row.status) == 0 ? 'primary' : 'warning'"
                >{{ Number(scope.row.status) == 0 ? "上架" : "下架" }}
              </el-button>
              <el-button size="mini" @click="Edits(scope.row)" type="primary"
                >编辑
              </el-button>
              <el-button type="danger" size="mini" @click="Deleted(scope.row)"
                >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pageNum"
        :page-size="queryinfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!-- 轮播新闻 /推荐新闻-->
    <el-dialog
      :close-on-click-modal="false"
      :title="titles"
      :visible.sync="swiperNewDialog"
      @close="swiperNewDialogClosed"
      width="55%"
    >
      <el-row :gutter="30" style="text-align: left; margin-left: 1px">
        <div style="display: inline-block">新闻标题：</div>
        <div style="display: inline-block">
          <el-input
            placeholder="请输入"
            v-model.trim="queryinfo1.title"
            clearable
            @clear="getList1"
            @input="getList1"
            style="width: 180px"
          ></el-input>
        </div>
      </el-row>
      <el-divider></el-divider>

      <i class="texts">可用的新闻列表</i>
      <el-button
        type="primary"
        icon="el-icon-circle-plus"
        style="float: right"
        @click="SelectedNew"
        >添加</el-button
      >
      <el-divider></el-divider>

      <el-row style="margin-right: 1px; margin-left: 1px; text-align: left">
        {{ contents }}
      </el-row>
      <el-row
        :gutter="30"
        style="display: flex; margin-right: 1px; margin-left: 1px"
      >
        <el-table
          :data="NewList1"
          border
          stripe
          @selection-change="handleAddChange"
          ref="multipleTable"
        >
          <el-table-column type="selection" width="50px"> </el-table-column>
          <el-table-column type="index" min-width="50px"></el-table-column>
          <el-table-column
            prop="title"
            label="新闻标题"
            align="center"
            min-width="200px"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="createTime"
            label="创建时间"
            align="center"
            min-width="150px"
          ></el-table-column>
        </el-table>
      </el-row>
      <el-pagination
        background
        @size-change="handleSizeChange1"
        @current-change="handleCurrentChange1"
        :current-page="queryinfo1.pageNum"
        :page-size="queryinfo1.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total1"
      >
      </el-pagination>

      <span slot="footer" class="dialog-footer">
        <el-button @click="swiperNewDialog = false">取消</el-button>
        <el-button type="primary" @click="confirmSets">确定</el-button>
      </span>
      <el-divider></el-divider>

      <i class="texts">已添加的{{ titles }}列表</i>
      <el-divider></el-divider>
      <el-row
        :gutter="30"
        style="display: flex; margin-right: 1px; margin-left: 1px"
      >
        <el-table :data="confirmSelectAdd" border stripe max-height="350">
          <el-table-column type="index" min-width="50px"></el-table-column>
          <el-table-column
            prop="title"
            label="新闻标题"
            align="center"
            min-width="200px"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="createTime"
            label="创建时间"
            align="center"
            min-width="150px"
          ></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="removeById(scope.row)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-dialog>

    <!-- 预览效果 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="titles"
      :visible.sync="previewDialog"
      width="70%"
      v-if="previewDialog"
    >
      <NEWdetail :id="previewID"></NEWdetail>
    </el-dialog>
  </div>
</template>
<script>
import ELselect from '@/components/myself/el_select'
import { deleteDialog } from '@/libs/confirmDialog'
import { setNewInfo, getShowingInfo, setShowingInfo, NewsListInfo } from '@/libs/commonApi.js'
import NEWdetail from './NEWdetail.vue'
import { dynamicColumnId } from '@/components/myself/dynamicCol'
export default {
  components: { ELselect, NEWdetail },
  data () {
    return {
      queryinfo: {
        type: '',
        title: '',
        pageNum: 1,
        pageSize: 20,
      },
      NewList: [],
      total: 0,
      Typeskeys: {
        value: 'value',
        label: 'label',
        label2: ''
      },
      Types: [
        {
          label: '公司新闻',
          value: 1
        }, {
          label: '产品动态',
          value: 2
        }],
      swiperNewDialog: false,
      multSelectAdd: [],
      multSelectAddIds: [],
      titles: '',
      contents: '',
      ifRecommend: false,

      previewDialog: false,
      previewID: '',
      totalPage: 1,

      queryinfo1: {
        title: '',
        pageNum: 1,
        pageSize: 5,
      },
      NewList1: [],
      total1: 0,
      confirmSelectAdd: []
    }
  },
  mounted () {
    this.getList()
  },

  methods: {
    async getList () {
      let quiese = { type: this.queryinfo.type, title: this.queryinfo.title }
      const res = await NewsListInfo(this.queryinfo.pageNum, this.queryinfo.pageSize, quiese)
      this.NewList = res.list
      this.total = res.total
      this.totalPage = res.pages
    },
    async onShelf (para) {
      let op = Number(para.status) == 0 ? 1 : 0
      let str = Number(para.status) == 0 ? '您确认上架当前新闻?' : '您确认下架当前新闻?'
      const Result = await deleteDialog(str, this)
      if (Result === 'confirm') {
        let Flag = await setNewInfo(para.id, op)
        if (Flag) {
          this.$message.success('操作成功')
          this.getList()
        }
      }
    },
    ViewNew (para) {
      this.titles = '新闻详情展示预览'
      this.previewID = para.id
      this.previewDialog = true
    },
    Edits (para) {
      this.$router.push({ path: '/addNew', query: { id: para.id } })
    },
    async Deleted (para) {
      const Result = await deleteDialog('您确认删除当前新闻?', this)
      if (Result === 'confirm') {
        let Flag = await setNewInfo(para.id, 2)
        if (Flag) {
          this.$message.success('操作成功')
          this.getList()
        }
      }
    },
    seachChange () {
      this.queryinfo.pageNum = 1
      this.getList()
    },
    clean () {
      this.$refs.queryinfoRef.resetFields()
      this.getList()
    },
    dataSearch () {
      this.getList()
    },

    handleSizeChange (newSize) {
      this.queryinfo.pageSize = newSize
      this.getList()
    },
    handleCurrentChange (newPage) {
      this.queryinfo.pageNum = newPage
      this.getList()
    },


    addNew () {
      this.$router.push('/addNew')
    },

    getSelectList () {
      if (this.confirmSelectAdd.length > 0) {
        this.$nextTick(function () {
          this.confirmSelectAdd.forEach(item => {
            this.NewList1.forEach(item1 => {
              if (item.id == item1.id) {
                this.$refs.multipleTable.toggleRowSelection(item1, true)
              }
            })
          })
        })
      }
    },
    async swiperNew () {
      this.titles = '轮播新闻'
      this.contents = '请选择需要轮播的新闻（选择2—5个）'
      this.confirmSelectAdd = await getShowingInfo(1)
      this.swiperNewDialog = true
      this.getList1()
    },
    swiperNewDialogClosed () {
      this.ifRecommend = false
      this.multSelectAddIds = []
      this.$refs.multipleTable.clearSelection()
      this.queryinfo1.pageNum = 1
      this.queryinfo1.title = ''
    },
    handleAddChange (val) {
      this.multSelectAdd = val
    },
    async confirmSets () {
      if (this.confirmSelectAdd.length >= 2 && this.confirmSelectAdd.length <= 5) {
        let op = ''
        if (this.ifRecommend) {
          op = 2
        } else {
          op = 1
        }
        this.confirmSelectAdd.forEach(item => {
          this.multSelectAddIds.push(item.id)
        })
        let Flag = await setShowingInfo(op, this.multSelectAddIds)
        if (Flag) {
          this.$message.success('操作成功')
          this.swiperNewDialog = false
        }
      } else {
        let str = !this.ifRecommend ? '请控制轮播个数在2-5间' : '请控制推荐个数在2-5间'
        return this.$message.error(str)
      }
    },
    async setRecommend () {
      this.titles = '推荐新闻'
      this.contents = '请选择需要推荐的新闻（选择2—5个）'
      this.ifRecommend = true
      this.confirmSelectAdd = await getShowingInfo(2)
      this.swiperNewDialog = true
      this.getList1()
    },

    handleSizeChange1 (newSize) {
      this.queryinfo1.pageSize = newSize
      this.getList1()
    },
    handleCurrentChange1 (newPage) {
      this.queryinfo1.pageNum = newPage
      this.getList1()
    },
    async getList1 () {
      let quiese = { title: this.queryinfo1.title }
      const res = await NewsListInfo(this.queryinfo1.pageNum, this.queryinfo1.pageSize, quiese)
      this.NewList1 = res.list
      this.total1 = res.total
      this.getSelectList()
    },

    SelectedNew () {
      this.confirmSelectAdd = this.confirmSelectAdd.concat(this.multSelectAdd)
      this.confirmSelectAdd = dynamicColumnId(this.confirmSelectAdd)
    },
    removeById (rowinfo) {
      this.confirmSelectAdd = this.confirmSelectAdd.filter((data) => { return data.id !== rowinfo.id })
      this.getList1()
    },

    // async loadMore () {
    //   if (this.queryinfo.pageNum < this.totalPage) {
    //     this.queryinfo.pageSize += 20
    //     const res = await NewsListInfo(this.queryinfo.pageNum, this.queryinfo.pageSize, {})
    //     this.NewList1 = res.list
    //   }
    // }
  }
}
</script>
<style lang="less" scoped>
.texts {
  color: blue;
  font-size: 16px;
}
.divSpan {
  font-size: 16px;
}
</style>