<template>
  <div class="emptyDiv">
    <el-empty
      :image-size="size"
      :description="description"
      :image="image"
      :class="emptyFlag"
    ></el-empty>
  </div>
</template>
<script>
export default {
  props: {
    size: {
      type: Number,
      default: 100
    },
    description: {
      type: String,
      default: '暂无数据'
    },
    image: {
      type: String
    },
    emptyFlag: {
      type: String
    },
  }
}
</script>
<style lang="less" scoped>
.emptyDiv {
  text-align: center;
  margin-top: 20px;
}

.classEmpty {
  padding: 0;
  float: left;
}
</style>