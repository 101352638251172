<template>
  <div class="divs">
    <div class="left">
      <div class="title">{{ newInfo.title }}</div>
      <div class="times">{{ newInfo.time }}</div>
      <div class="bords"></div>
      <div class="desc" v-html="newInfo.url"></div>
      <div class="bords"></div>
      <div class="upNew" @click="upNew" v-show="prevName.title">
        上一篇 <span class="spans">{{ prevName.title }}</span>
      </div>
      <div class="nextNew" @click="nextNew" v-show="nextName.title">
        下一篇 <span class="spans">{{ nextName.title }}</span>
      </div>
    </div>

    <!-- right -->
    <Recommend :bodyContent="bodyContent" class="right"></Recommend>
  </div>
</template>
<script>
import Recommend from './Recommend.vue'
import { getDetailInfo, getShowingInfo, getUrlContentList, getPrevInfo, getNextInfo } from '@/libs/commonApi.js'

export default {
  components: { Recommend },
  props: {
    id: {
      type: String
    }
  },
  data () {
    return {
      bodyContent: [],
      newInfo: {
        url: '',
        title: '',
        time: ''
      },
      nextName: {},
      prevName: {},
    }
  },
  mounted () {
    this.getDescribe()
    this.getDetail()
  },
  methods: {
    async getDetail () {
      const res = await getDetailInfo(this.id)
      this.newInfo.title = res.title
      this.newInfo.time = res.eventTime
      let result = await getUrlContentList(res, 2)
      this.newInfo.url = result.urlContent

      this.prevName = await getPrevInfo(this.id)
      this.nextName = await getNextInfo(this.id)
    },

    async getDescribe () {
      const res = await getShowingInfo(2)
      this.bodyContent = await getUrlContentList(res)
    },

    Details () { },
    upNew () { },
    nextNew () { },
  }
}
</script>
<style lang="less" scoped>
.divs {
  display: flex;
  // justify-content: center;
  // align-items: center;
  margin-top: 20px;

  .left {
    min-width: 68%;
    text-align: left;
    margin-left: 50px;
    .title {
      font-size: var(--Font24);
      font-family: var(--fontFamily1);
      font-weight: var(--fontWeight);
    }
    .times {
      font-size: var(--Font14);
      font-family: var(--fontFamily1);
      font-weight: var(--fontWeight);
      color: #939393;
      margin-top: 26px;
      margin-bottom: 24px;
    }
    .bords {
      border: 1px solid #f3f3f3;
      margin-bottom: 30px;
      margin-top: 30px;
    }
    .desc {
      font-size: var(--Font16);
      font-family: var(--fontFamily1);
      font-weight: var(--fontWeight);
      color: #767270;
      line-height: 30px;
      margin-bottom: 30px;

      ::v-deep img {
        max-width: 100%;
        height: 1920/1080 * 100%;
      }
    }
    /deep/.desc {
      width: auto !important;
    }
    .upNew {
      font-size: var(--Font14);
      font-family: var(--fontFamily1);
      font-weight: var(--fontWeight);
      color: #4c4c4c;
      margin-bottom: 30px;
      margin-top: 10px;
      cursor: pointer;
    }
    .nextNew {
      font-size: var(--Font14);
      font-family: var(--fontFamily1);
      font-weight: var(--fontWeight);
      color: #4c4c4c;
      cursor: pointer;
    }
    .spans {
      margin-left: 20px;
    }
  }
  .right {
    text-align: left;
    margin-left: 50px;
    margin-right: 50px;
    // width: 20%;
  }
}
</style>