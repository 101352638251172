<template>
  <div class="right">
    <div class="title">推荐新闻</div>
    <div class="title1">RECOMMENDED</div>
    <div v-if="bodyContent.length > 0">
      <div v-for="(banner, index) in bodyContent" :key="index" class="divList">
        <div :class="index == 0 ? 'bords1' : 'bords'"></div>
        <div class="content" @click="Details(banner)" style="cursor: pointer">
          <div class="desc" :title="banner.title">{{ banner.title }}</div>
          <div class="times">
            {{ banner.eventTime }}
          </div>
        </div>
        <div class="bords" v-show="index == bodyContent.length - 1"></div>
      </div>
    </div>
    <div v-else>
      <div class="divList">
        <div class="bords1"></div>
      </div>
      <EmptyEL></EmptyEL>
    </div>
  </div>
</template>
<script>
import EmptyEL from '@/components/myself/el_empty.vue'
export default {
  components: { EmptyEL },
  props: {
    bodyContent: {
      type: Array,
      default: () => {
        return []
      }
    },
  },

  methods: {
    Details (item) {
      this.$emit('Details', item)
    }
  }
}
</script>
<style lang="less" scoped>
.right {
  .title {
    font-size: var(--Font18);
    font-family: var(--fontFamily1);
    font-weight: var(--fontWeight);
    color: #4c4c4c;
  }
  .title1 {
    margin-top: 20px;
    margin-bottom: 24px;
    font-size: var(--Font14);
    font-family: var(--fontFamily1);
    font-weight: var(--fontWeight);
    color: #939393;
  }

  .divList {
    .bords1 {
      border: 1px solid #f3f3f3;
      margin-bottom: 22px;
    }
    .bords {
      border: 1px solid #f3f3f3;
      margin-bottom: 22px;
      margin-top: 22px;
    }
    .content {
      .desc {
        font-size: var(--Font16);
        font-family: var(--fontFamily1);
        font-weight: var(--fontWeight);
        color: #767270;
        line-height: 30px;
        margin-bottom: 10px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        word-break: break-all;
        word-break: break-word;
        min-width: 200px;
      }
      .times {
        font-size: var(--Font14);
        font-family: var(--fontFamily1);
        font-weight: var(--fontWeight);
        color: #939393;
      }
    }
  }
}
</style>